<template>
  <div class="plants-grow">
      <div class="nav">
          <div class="top-title">
            <div class="top-title-iocn" @click="goBack"></div>
            <div class="top-title-content">{{ goodsInfo.processName }}</div>
        </div>
      </div>
       
       <div class="show-img">
            <img class="img"
                width="100%"
                height="100%"
                fit="contain"
                :src="goodsInfo.processImg"
                />
       </div>
       <div class="tree-info">
           <p class="info">{{ goodsInfo.processName }}</p>
           <p class="date">{{ goodsInfo.processDate }}</p>
       </div>
       <div class="tree-detail">
           <ul>
               <li v-for="(item,index) in goodsInfo.processList" :key="index">
                   <div class="top">
                       <div class="icon"></div>
                       <div class="period">{{item.processName}}</div>
                   </div>
                   <div class="bottom">
                       <div class="date">{{item.startTimeStr}}</div>
                   </div>
               </li>
           </ul>
       </div>
  </div>
</template>

<script>
import { getProcessInfo } from '@/api/suyuan'
export default {
  name: 'plantsGrow',
  data(){
      return {
          goodsInfo:{
              processName:'',
              processImg:'',
              processDate:'',
              processList:[]
          },
          processId:'',
          batchsId:''
      }
  },
  mounted(){
    //获取路由跳转的过程id
    this.processId = this.$route.params.hasOwnProperty('processId') ? this.$route.params.processId : '';
    //获取路由跳转的批次id
    this.batchsId = this.$route.params.hasOwnProperty('batchsCode') ? this.$route.params.batchsCode : '';
    //获取过程信息
    this.getProcessInfo();
  },
  methods:{
      /**
       * 导航返回事件
       */
      goBack(){
          this.$router.go(-1)
      },
      /**
       * 获取过程信息
       */
      getProcessInfo(){
          getProcessInfo({batchsId:this.batchsId,processId: this.processId}).then( res => {
              let data = res.data;
              this.goodsInfo.processName = data.processName;
              this.goodsInfo.processDate = data.startTimeStr;
              this.goodsInfo.processImg = data.imgUrl;
              this.goodsInfo.processList = data.processList;
          })
      }
  }
}
</script>
<style lang="scss" scoped>
    .plants-grow {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
         .nav {
            width: 100%;
            height: 64px;
            .top-title {
                position: fixed;
                display: flex;
                align-items: center;
                width: 100%;
                height: 64px;
                box-sizing: border-box;
                padding-top: 22px;
                z-index: 99;
                .top-title-iocn {
                    position:absolute;
                    width: 16px;
                    height: 16px;
                    background:url('../../../assets/planA/back.png') no-repeat top center;
                    background-size: cover;
                    z-index: 2;
                }
                .top-title-content {
                    position:absolute;
                    left:calc(50% - 16px);
                    transform: translate(-50%);
                    text-align: center;
                    font-size: 18px;
                    color: #000000;
                    z-index: 1;
                }
            }
        }
        .show-img {
            width: 100%;
            height: 180px;
            margin-top: 25px;
            border-radius: 10px;
            .img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }
        .tree-info {
            width: 100%;
            height: 62px;
            box-sizing: border-box;
            margin-top: 15px;
            border-bottom: 1px solid  #F0F0F0;
            .info {
                width: 100%;
                height: 28px;
                line-height: 28px;
                font-size: 20px;
                color: #262626;
            }
            .date {
                width: 100%;
                height: 21px;
                line-height: 21px;
                font-size: 14px;
                color: #8C8C8C;
            }
        }
        .tree-detail {
            width: 100%;
            padding: 12px 0;
            ul {
                width: 100%;
                li {
                    padding-bottom: 12px;
                    .top {
                        display: flex;
                        align-items: center;
                        .icon {
                            width: 10px;
                            height: 10px;
                            box-sizing: border-box;
                            border:1px solid #FB8502;
                            border-radius: 50%;
                        }
                        .period{
                            flex: 1;
                            padding-left: 12px;
                            font-size: 16px;
                            color: #262626;
                        }
                    }
                    .bottom {
                        width: 100%;
                        height: 24px;
                        line-height: 24px;
                        margin-top: 2px;
                        font-size: 14px;
                        color: #8C8C8C;
                        box-sizing: border-box;
                        padding-left: 22px;
                    }
                }
            }
        }
    }
</style>